import React, { useState, useEffect } from "react";
import { Container, Form, Button, Modal, InputGroup } from 'react-bootstrap';
import { auth, generateShortLink, SECRET_KEY } from "../../firebase.js";
import { useAuthState } from "react-firebase-hooks/auth";
import axios from 'axios';
import cheerio from 'cheerio';
import Countdown from 'react-countdown';
import CryptoJS from 'crypto-js';
import "./home.css";
import "./../../Global/shared-styles.css"
// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
// import { firebaseConfig } from "../../firebase.js";
import { GoSignOut } from "react-icons/go";
import { ToastContainer } from 'react-toastify';
import { PiStarFourFill } from "react-icons/pi";
import { isMobile } from 'react-device-detect';
import MobileNotSupport from "../MobileNotSupport/mobilesupport.jsx";
import { constants } from "../../Global/constants.js";

// const app = initializeApp(firebaseConfig);
// const db = getFirestore(app);

const isURL = (text) => {
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlPattern.test(text);
};

const isSecureURL = (text) => {
    const urlPattern = /^(https):\/\/[^ "]+$/;
    return urlPattern.test(text);
};

const checkValidation = (linkTitle, originalLink, isCustomLinkSwitchOn, customLink, setModalMessage, setShowModal, setIsCreateShortLinkBtnLoading) => {
    if (linkTitle == null || originalLink == null) {
        setModalMessage('Link Title or Original Link can not be empty!');
        setShowModal(true);
    } else if (!isURL(originalLink)) {
        setModalMessage('Original Link is missing!');
        setShowModal(true);
    } else if (!isSecureURL(originalLink)) {
        setModalMessage('Original Link is not secure!');
        setShowModal(true);
    } else if (isCustomLinkSwitchOn && customLink == null) {
        setModalMessage('Custom Link can not be empty!');
        setShowModal(true);
    } else if (isCustomLinkSwitchOn && customLink.length > 16) {
        setModalMessage('The custom link must not exceed 16 characters in length');
        setShowModal(true);
    } else {
        setIsCreateShortLinkBtnLoading(true);
        generateShortLink(linkTitle, originalLink, customLink).then((res) => {
            console.log(res)
            if (res.code === 200) {
                setIsCreateShortLinkBtnLoading(false);
                localStorage.setItem("code", res.code);
                localStorage.setItem("message", res.message);
                localStorage.setItem("generatedURL", res.customid);
            } else {
                setIsCreateShortLinkBtnLoading(false);
                localStorage.setItem("code", res.code);
                localStorage.setItem("message", res.message);
            }
            window.location = '/transmissionstatus';
        })

    }
}

const handleCountdownComplete = () => {
    auth.signOut()
        .then(() => {
            // Logout successful
            console.log('User logged out.');
            localStorage.removeItem('ue');
            localStorage.clear();
            window.localStorage.clear();
            window.location = '/';
        })
        .catch((error) => {
            // Handle logout error
            console.error('Error logging out:', error);
        });
};

const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <handleCountdownComplete />;
    } else {
        // Render a countdown
        return <span className="expireOnAlign">Expired On : {minutes}:{seconds}</span>;
    }
};

export default function Home() {

    const currentYear = new Date().getFullYear();

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const [linkTitle, setLinkTitle] = useState("");
    const [originalLink, setOriginalLink] = useState("");
    const [customLink, setCustomLink] = useState("");
    const [characterCount, setCharacterCount] = useState(0);

    const [currentuser, setCurrentUser] = useState(null);

    const [isAiBtnLoading, setIsAiBtnLoading] = useState(false);

    const [isCreateShortLinkBtnLoading, setIsCreateShortLinkBtnLoading] = useState(false);

    const [lastActivity, setLastActivity] = useState(Date.now());

    const handleActivity = () => {
        setLastActivity(Date.now());
    };

    useEffect(() => {
        // Listen for user activity
        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keypress', handleActivity);

        return () => {
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keypress', handleActivity);
        };
    }, []);

    const dataciphertext = JSON.parse(localStorage.getItem('ue'));

    // Decrypt
    const bytes = CryptoJS.AES.decrypt(dataciphertext, SECRET_KEY);
    const originalTextofEmail = bytes.toString(CryptoJS.enc.Utf8);

    const [user, cusloading, error] = useAuthState(auth);

    const handleCustomLinkInputChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue.length <= 16) {
            setCustomLink(inputValue);
            setCharacterCount(inputValue.length);
        }
    };

    useEffect(() => {
        if (!cusloading && !user) {
            window.location.href = '/';
        }
    }, [user, cusloading]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                // User is signed in.
                setCurrentUser(user);
            } else {
                // User is signed out.
                setCurrentUser(null);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    const handleFetchTitle = async (url) => {
        setIsAiBtnLoading(true);
        setLinkTitle('');
        if (url === null) {
            alert('Error: Original link is null.');
        } else if (!isURL(url)) {
            alert('Error: Original link is missing.');
        } else if (isSecureURL(url) === false) {
            alert('Error: Original link is not secure.');
        } else {
            try {
                const proxyUrl = 'https://api.allorigins.win/raw?url=';
                const response = await axios.get(proxyUrl + url);
                const html = response.data;
                const $ = cheerio.load(html);
                const pageTitle = $('head title').text();
                setLinkTitle(pageTitle);
                console.log(linkTitle);
            } catch (error) {
                console.error('Error fetching URL:', error);
            }
        }
        setIsAiBtnLoading(false);

    };

    const [isCustomLinkSwitchOn, setIsCustomLinkSwitchOn] = useState(false);

    return (
        <>
            {isMobile ? <MobileNotSupport /> :
                <>
                    <Modal show={showModal} onHide={() => setShowModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Error</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <p>{modalMessage}</p>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="logincontainer">

                        <div className='d-flex justify-content-between logoutbtn'>

                            <div>
                                <Countdown
                                    className="m-3"
                                    date={lastActivity + 300000}
                                    renderer={renderer}
                                    onComplete={handleCountdownComplete}
                                />
                                <button className='btn btn-outline-secondary m-3 customLogoutBtnBg customLogoutBtn' onClick={(event) => {
                                    event.preventDefault(); // Cancel the default browser behavior

                                    // Perform logout here
                                    auth.signOut()
                                        .then(() => {
                                            // Logout successful
                                            console.log('User logged out.');
                                            localStorage.removeItem('ue');
                                            localStorage.clear();
                                            window.location = '/';
                                        })
                                        .catch((error) => {
                                            // Handle logout error
                                            console.error('Error logging out:', error);
                                        });
                                }}>Logout<GoSignOut className="m-1" /></button>
                            </div>

                            <div className="d-flex uinfogp">

                                <div className="currentEmail">
                                    
                                    {originalTextofEmail}
                                </div>

                                {/* <div className="currentEmail">{tontxtonly}</div> */}
                                {/* {govagencytxtonly && <div className="currentEmail">{govagencytxtonly}</div>} */}

                                {/* {verifytxtonly !== '' && <img src={verifytxtonly} height={50} className="verifygovlogo" />} */}
                            </div>

                        </div>

                        <ToastContainer />

                        <Container className='align-items-center d-flex navless-container containerheight'>
                            <Container className='align-items-center d-flex navless-container containerheight'>

                                <div className="m-auto formwidth">
                                    <img src={constants.logo} width={80} className="godotnuglogo align-items-center" />

                                    <Form.Group className="my-3" controlId="formLinkTitle">
                                        <Form.Label>Link Title *</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                value={linkTitle}
                                                onChange={e => setLinkTitle(e.target.value)}
                                            />
                                            {/* <InputGroup.Append> */}
                                            <Button
                                                variant="outline-dark"
                                                onClick={() => handleFetchTitle(originalLink)}
                                                disabled={!originalLink}
                                                className="AiBtn"
                                            >
                                                {isAiBtnLoading ?
                                                    <PiStarFourFill
                                                        className="pistar-icon"
                                                        style={{ animation: 'spin 1s linear infinite', marginTop: '-0.2rem', color: '#2F3C87' }}
                                                    />
                                                    :
                                                    <PiStarFourFill
                                                        className="pistar-icon"
                                                        style={originalLink && isURL(originalLink) & isSecureURL(originalLink) ? { animation: 'appear 1s ease-out', marginTop: '-0.1rem', color: '#2F3C87' } : {}}
                                                    />
                                                }
                                            </Button>
                                            {/* </InputGroup.Append> */}
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group className="my-3" controlId="formLinkSlug">
                                        <Form.Label>Original Link * <span className="smalltextsize"> All of link are should be start with <span className="httpscolor">https://</span></span></Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                // disabled={!verifytxtonly}
                                                type="url"
                                                onChange={e => setOriginalLink(e.target.value)}
                                            />
                                            {/* <InputGroup.Append> */}
                                            {/* <Button variant="outline-secondary">Detect Title</Button> */}
                                            {/* </InputGroup.Append> */}
                                        </InputGroup>
                                    </Form.Group>

                                    <div className="d-flex gap-2 text-align-center">
                                        <Form.Check className="switchBorder" type="switch" id="custom-switch" label="Custom Link"
                                            // disabled={!verifytxtonly}
                                            checked={isCustomLinkSwitchOn} onChange={() => setIsCustomLinkSwitchOn(!isCustomLinkSwitchOn)} />
                                        {/* <span className="smalltextsize currentEmail">If disable custom link auto generated</span> */}
                                        {/* <Form.Label className="smalltextsize"> If disable custom link auto generated</Form.Label> */}
                                    </div>

                                    {isCustomLinkSwitchOn && (
                                        <Form.Group className="my-3" controlId="formCustomLinkSlug">
                                            <div className="d-flex" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Form.Label>Custom Link *
                                                </Form.Label>
                                                <p style={characterCount > 9 ? (characterCount === 16 ? { color: 'red' } : { color: 'orange' }) : { color: 'gray' }}>{characterCount} / 16</p>
                                            </div>
                                            <InputGroup className="mb-2"
                                            // disabled={!verifytxtonly}
                                            >
                                                <InputGroup.Text className="text-muted">edu.nugmyanmar.org/</InputGroup.Text>
                                                <Form.Control type="url" value={customLink} onChange={handleCustomLinkInputChange} />
                                            </InputGroup>
                                        </Form.Group>
                                    )}

                                    <div className="d-grid gap-2">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            className='loginBtn'
                                            // disabled={!verifytxtonly}
                                            onClick={() =>
                                                checkValidation(linkTitle, originalLink, isCustomLinkSwitchOn, customLink, setModalMessage, setShowModal, setIsCreateShortLinkBtnLoading)
                                            }>
                                            {isCreateShortLinkBtnLoading ?
                                                // <PiStarFourFill
                                                //     className="pistar-icon"
                                                //     style={{ animation: 'spin 1s linear infinite', marginTop: '-0.1rem', color: 'red' }}
                                                // />
                                                'Loading...'
                                                :
                                                'Generate Shorten Link'
                                            }
                                        </Button>
                                    </div>

                                    {/* <hr className="m-y:6" />

<div className="d-grid">
<Button variant="outline-secondary" type="submit" className='loginBtn deleteBtn' onClick={(event) => {
event.preventDefault(); // Cancel the default browser behavior
window.location = '/delete.adm';
}}>
Delete Link
</Button>
</div> */}

                                    <hr className="m-y:6" />

                                    <div className="d-flex">
                                                <Button
                                                    variant="outline-secondary"
                                                    type="submit"
                                                    className='loginBtn deleteBtn'
                                                    style={{
                                                        width: '70%',
                                                        marginRight: '0.5rem'
                                                    }}
                                                    onClick={(event) => {
                                                        event.preventDefault(); // Cancel the default browser behavior
                                                        window.location = '/lists.adm';
                                                    }}>
                                                    View Created Shorten Links
                                                </Button>
                                                <Button
                                                    variant="outline-secondary"
                                                    type="submit"
                                                    className='loginBtn deleteBtn'
                                                    style={{
                                                        width: '30%',
                                                        marginLeft: '0.5rem'
                                                    }}
                                                    onClick={(event) => {
                                                        event.preventDefault(); // Cancel the default browser behavior
                                                        window.location = '/whois';
                                                    }}>
                                                    WHOIS
                                                </Button>
                                            </div>

                                </div>
                            </Container >
                        </Container>
                        <div className="footer" hidden={isCustomLinkSwitchOn}>
                            <p className="text-muted">© {currentYear} NUG Myanmar </p>
                        </div>
                    </div >
                </>
            }

        </>

    )
}