import React, { useEffect, useState } from 'react';
import '../../App.css';
import { getShortenLinksData, removeShortenLinksData, firebaseConfig } from '../../firebase.js';
import { updateDoc, doc, getDocs, collection, query, where, getDoc, addDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navbar, Container, Modal, Col, Table, Button, Card, Form, Alert, InputGroup, Badge } from "react-bootstrap";
import { BiTrash, BiCopy, BiExpand } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import Countdown from 'react-countdown';
import { auth, SECRET_KEY } from "../../firebase.js";
import CryptoJS from 'crypto-js';
import Loading from "../Loading/Loading";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { initializeApp } from "firebase/app";
import { getFirestore, arrayUnion } from "firebase/firestore";
import '../List/list.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaSearch } from "react-icons/fa";
import { constants } from "../../Global/constants.js";

function LabelWithMaxLength({ text, maxLength, className }) {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    const openTextOnModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const copyToClipboard = () => {
        // Create a temporary textarea element to copy text to the clipboard
        const textArea = document.createElement("textarea");
        textArea.value = text;

        // Append the textarea to the document
        document.body.appendChild(textArea);

        // Select the text in the textarea
        textArea.select();

        // Copy the selected text to the clipboard
        document.execCommand("copy");

        // Remove the textarea from the document
        document.body.removeChild(textArea);

        // Set the "isCopied" state to true to show a copy confirmation
        setIsCopied(true);

        // Reset the "isCopied" state after a few seconds
        setTimeout(() => setIsCopied(false), 2000);

        toast.success('Copied data to clipboard!', {});
    };

    return (
        <div>
            <label className={className}>
                {text.length > maxLength ? text.substring(0, maxLength) + "..." : text}
            </label>
            <button onClick={copyToClipboard} className="copy-button">
                {isCopied ? <IoMdCheckmarkCircleOutline /> : <BiCopy />}
            </button>

            <Modal show={modalIsOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Expanded View <FaEye /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ overflowWrap: 'break-word' }} className='modalBody'>{text}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={copyToClipboard} >
                        {isCopied ? 'Copied' : 'Copy'}
                    </Button>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <button onClick={openTextOnModal} className="copy-button">
                {modalIsOpen ? <IoMdCheckmarkCircleOutline /> : (text.length < maxLength ? "" : <FaEye />)}
            </button>
        </div>
    );
}

function formatDate(datenew) {
    const date = new Date(datenew);
    const formattedDate = `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
    return formattedDate;
}

const isSecureURL = (text) => {
    const urlPattern = /^(https):\/\/[^ "]+$/;
    return urlPattern.test(text);
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function WhoIS() {

    const currentYear = new Date().getFullYear();
    const [shortenLists, addData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [user, cusloading, error] = useAuthState(auth);

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const [linkInfoShow, setLinkInfoShow] = useState(false);
    const [linkInfoCreatedDate, setLinkInfoCreateDate] = useState('');
    const [linkInfoCreatedUser, setLinkInfoCreatedUser] = useState('');
    const [linkInfoName, setLinkInfoName] = useState('');
    const [linkInfoActive, setLinkInfoActive] = useState(false);
    const [linkInfoRefID, setLinkInfoRefID] = useState('');

    const [announcementShow, setAnnouncementShow] = useState(false);
    const [announcementData, setAnnouncementData] = useState(null);

    const [lastActivity, setLastActivity] = useState(Date.now());

    const handleActivity = () => {
        setLastActivity(Date.now());
    };

    useEffect(() => {
        // Listen for user activity
        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keypress', handleActivity);

        return () => {
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keypress', handleActivity);
        };
    }, []);

    useEffect(() => {
        const fetchAnnouncement = async () => {
            try {
                const docRef = doc(db, "gotoconst", "announcement");
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setAnnouncementShow(data.show);
                    setAnnouncementData(data.text);
                    setLoading(false);
                } else {
                    console.log("No document found!");
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching announcement:', error);
                setLoading(false);
            }
        };

        fetchAnnouncement();
    }, []);

    const dataciphertext = JSON.parse(localStorage.getItem('ue'));

    // Decrypt
    const bytes = CryptoJS.AES.decrypt(dataciphertext, SECRET_KEY);
    const originalTextofEmail = bytes.toString(CryptoJS.enc.Utf8);

    const checkLink = async (data) => {
        if (data.length === 0) {
            setShowModal(true);
            setModalMessage('Please enter the shorten link.');
            return;
        }

        const docRef = doc(db, "goto", data);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            await addDoc(collection(db, "whoislog"), {
                timestamp: Date.now(),
                link: data,
                user: originalTextofEmail
            }).then((docRef) => {
                setLinkInfoRefID(docRef.id);
                console.log("Document written with ID: ", docRef.id);
            });
            setLinkInfoCreateDate(docSnap.data().timestamp);
            setLinkInfoCreatedUser(docSnap.data().addedby);
            setLinkInfoName(docSnap.data().name);
            setLinkInfoActive(docSnap.data().active);
            setLinkInfoShow(true);
        } else {
            console.log("No such document!");
            setLinkInfoShow(false)
            setShowModal(true);
            setModalMessage('This shorten link is available.');
        }

    }

    useEffect(() => {
        if (!cusloading && !user) {
            window.location.href = '/';
        }
    }, [user, cusloading]);

    const handleCountdownComplete = () => {
        auth.signOut()
            .then(() => {
                // Logout successful
                console.log('User logged out.');
                localStorage.removeItem('ue');
                localStorage.clear();
                window.localStorage.clear();
                window.location = '/';
            })
            .catch((error) => {
                // Handle logout error
                console.error('Error logging out:', error);
            });
    };

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <handleCountdownComplete />;
        } else {
            // Render a countdown
            return <span className="expireOnAlign">Expired On : {minutes}:{seconds}</span>;
        }
    };

    function handleSearchTermChange(e) {
        let value = e.target.value;
        if (value.startsWith('https://edu.nugmyanmar.org/')) {
            value = value.substring(27);
        }
        setSearchTerm(value);
    }

    const [searchTerm, setSearchTerm] = useState('');

    return (
        <>
            <ToastContainer />
            {loading === false ? (

                <Container fluid className={`container-fluid`}>
                    <div className='d-flex justify-content-between logoutbtn'>

                        <div>
                            <Countdown
                                className="m-3"
                                date={Date.now() + 300000}
                                renderer={renderer}
                                onComplete={handleCountdownComplete}
                            />
                            <button
                                className="btn btn-outline-secondary m-3"
                                onClick={(event) => {
                                    event.preventDefault();
                                    window.location = '/home.adm';
                                }}
                            >
                                Back to Home
                            </button>
                        </div>

                        <div className='navbar'>
                            <Navbar.Brand href="/home.adm">
                                <img
                                    src={constants.logo}
                                    width="40"
                                    className="d-inline-block align-center"
                                    alt="Edu.NUG Logo"
                                />
                            </Navbar.Brand>
                            <span className='slug text-muted'>/</span>
                            <span className="slug text-muted">WHOIS <Badge bg="secondary">New</Badge></span>
                        </div>

                    </div>

                    <Modal show={showModal} onHide={() => setShowModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Info</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <p>{modalMessage}</p>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Col className="ms-sm-auto">
                        <Container className="datacontainer p-0">
                            {announcementShow ? (
                                <Alert variant="secondary">
                                    <svg viewBox="0 0 14 16" width={18} className='me-2'>
                                        <path fill-rule="evenodd" d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z">
                                        </path>
                                    </svg>{announcementData}
                                </Alert>
                            ) : (null)}

                            <Alert variant="secondary">
                                <svg viewBox="0 0 14 16" width={18} className='me-2'>
                                    <path fill-rule="evenodd" d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z">
                                    </path>
                                </svg>
                                We collected your search log data for the security purpose. <a href="https://edu.nugmyanmar.org/privacy" target="_blank">Privacy Policy</a>
                            </Alert>

                            <div>
                                <InputGroup className="mb-3" size='lg'>
                                    <InputGroup.Text id="basic-addon2">edu.nugmyanmar.org/</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Enter shorten link"
                                        aria-label="Enter shorten link"
                                        aria-describedby="shorten-link"
                                        value={searchTerm}
                                        onChange={handleSearchTermChange}
                                        type='text'
                                    />
                                    <Button
                                        variant="outline-secondary"
                                        id="button-addon2"
                                        onClick={() => { checkLink(searchTerm) }}>
                                        Check <FaSearch />
                                    </Button>
                                </InputGroup>
                            </div>

                            {linkInfoShow ? (
                                <Card>
                                    <div style={{ textAlign: 'center', marginTop: '6rem', marginBottom: '6rem' }} >
                                        <Container style={{ width: '30%' }}>
                                            <h3>{linkInfoName}</h3>
                                            <p>
                                                This concise link, named <b>"{linkInfoName},"</b> is currently <b>{linkInfoActive ? 'available' : 'unavailable'}</b> and was created by <b>{linkInfoCreatedUser}</b> on <b>{linkInfoCreatedDate}</b> (Myanmar Standard Times).
                                            </p>
                                            <p className='text-muted'>{linkInfoRefID}</p>
                                        </Container>
                                    </div>
                                </Card>

                            ) : (
                                <div style={{ textAlign: 'center', marginTop: '2rem', marginBottom: '2rem' }} >
                                    <Container style={{ width: '30%' }}>
                                        <h3>What link are you looking for?</h3>
                                        <p>Search by short link to see what links other pubic officers have created, and find link owners. WHOIS is only available to users who are verify user.</p>
                                        <img src="https://cdn.egov.nugmyanmar.org/assets/state/go.nug-whois.png" alt="Go.NUG Logo" width={250} />
                                    </Container>
                                </div>
                            )}

                        </Container>
                    </Col >

                </Container >
            ) : (
                <>
                    <Loading />
                </>
            )
            }
        </>
    );
}

export default WhoIS;