import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { initializeApp } from "firebase/app";
import { firebaseConfig } from '../../firebase.js';
import { doc, getDoc, getDocs, collection, query } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { toast, ToastContainer } from 'react-toastify';
import { Navbar, Container, Modal, Col, Table, Button, Row, Alert } from "react-bootstrap";
import Loading from "../Loading/Loading";
import Countdown from 'react-countdown';
import { auth, SECRET_KEY } from "../../firebase.js";
import { useAuthState } from "react-firebase-hooks/auth";
import { BsPrinterFill } from "react-icons/bs";
import Masonry from 'react-masonry-css';
import './statisticreport.css';
import { constants } from "../../Global/constants.js";
import { BsDot } from "react-icons/bs";

let params = new URLSearchParams(window.location.search);
let statisticsid = params.get('sid');
console.log('Statistics ID:', statisticsid);

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const breakpointColumnsObj = {
    default: 3, // 3 columns by default
    1100: 3, // 3 columns for 1100px and larger screens
    700: 2, // 2 columns for 700px and larger screens
    500: 1 // 1 column for 500px and larger screens
};

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Chart.js Bar Chart',
        },
    },
};

export function StatisticsReport() {

    const currentYear = new Date().getFullYear();

    const [linkStatistics, setLinkStatistics] = useState([]);
    const [loading, setLoading] = useState(true);
    const [user, cusloading, error] = useAuthState(auth);

    const [statDataLoading, setStatDataLoading] = useState(false);

    let cdate = new Date();
    let currentDate = `${('0' + cdate.getDate()).slice(-2)}_${('0' + (cdate.getMonth() + 1)).slice(-2)}_${cdate.getFullYear()}`;

    const [selectedDate, setSelectedDate] = useState(currentDate);

    useEffect(() => {
        const fetchLinkStatistics = async () => {
            try {
                const docRef = doc(db, "linkStatistics", statisticsid);
                const docSnap = await getDoc(docRef);
                console.log('Link Statistics:', docSnap);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    let activities = data[selectedDate]; // replace "19_02_2024" with the actual date                    console.log("Activities:", activities);
                    setLinkStatistics(activities || []); // If activities is undefined, set linkStatistics to an empty array
                    setLoading(false);
                } else {
                    console.log("No document found!");
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching link statistics:', error);
                setLoading(false);
            }
        };

        fetchLinkStatistics();
    }, [db, statisticsid]);

    // Generate an array of dates from 14 days ago to today format as yyyy-mm-dd
    // let dates = Array.from({ length: 15 }, (_, i) => {
    //     let d = new Date();
    //     d.setDate(d.getDate() - i);
    //     return d.toISOString().split('T')[0]; // format as yyyy-mm-dd
    // });

    // Generate an array of dates from 14 days ago to today
    let dates = Array.from({ length: 15 }, (_, i) => {
        let d = new Date();
        d.setDate(d.getDate() - i);
        return `${('0' + d.getDate()).slice(-2)}_${('0' + (d.getMonth() + 1)).slice(-2)}_${d.getFullYear()}`;
    });

    // dates.unshift('All', 'Monthly');

    const fetchLinkStatistics = async () => {
        try {
            setStatDataLoading(true);
            const docRef = doc(db, "linkStatistics", statisticsid);
            const docSnap = await getDoc(docRef);
            console.log('Link Statistics:', docSnap);
            if (docSnap.exists()) {
                const data = docSnap.data();
                let activities = data[selectedDate]; // replace "19_02_2024" with the actual date                    console.log("Activities:", activities);
                setLinkStatistics(activities || []); // If activities is undefined, set linkStatistics to an empty array
                setStatDataLoading(false);
            } else {
                console.log("No document found!");
                setStatDataLoading(false);
            }
        } catch (error) {
            console.error('Error fetching link statistics:', error);
            setStatDataLoading(false);
        }
    };

    // const fetchAllLinkStatistics = async () => {
    //     try {
    //         setStatDataLoading(true);

    //         const q = query(collection(db, "linkStatistics"), statisticsid);

    //         const querySnapshot = await getDocs(q);
    //         querySnapshot.forEach((doc) => {
    //             console.log(doc.id, " => ", doc.data());
    //         });

    //     } catch (error) {
    //         console.error('Error fetching link statistics:', error);
    //         setStatDataLoading(false);
    //     }
    // };

    // const fetchMonthlyLinkStatistics = async () => {
    //     try {
    //         setStatDataLoading(true);
    //         const docRef = doc(db, "linkStatistics", statisticsid);
    //         const docSnap = await getDoc(docRef);
    //         console.log('Link Statistics:', docSnap);
    //         if (docSnap.exists()) {
    //             const data = docSnap.data();
    //             let activities = data[dates]; // replace "19_02_2024" with the actual date                    console.log("Activities:", activities);
    //             setLinkStatistics(activities || []); // If activities is undefined, set linkStatistics to an empty array
    //             setStatDataLoading(false);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching link statistics:', error);
    //         setStatDataLoading(false);
    //     }
    // };

    // Define fetchLinkStatisticsAgain function
    const fetchLinkStatisticsAgain = (date) => {
        setSelectedDate(date);
    };

    // Use useEffect hook to call fetchLinkStatistics when selectedDate changes
    useEffect(() => {
        if (selectedDate) {
            fetchLinkStatistics();
        }
    }, [selectedDate]);

    //  const data = {
    //     labels,
    //     datasets: [
    //         {
    //             label: 'Dataset 1',
    //             data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
    //             borderColor: 'rgb(255, 99, 132)',
    //             backgroundColor: 'rgba(255, 99, 132, 0.5)',
    //         }
    //     ],
    // };

    // Count the occurrences of each country
    let countryCounts = linkStatistics.reduce((acc, stat) => {
        acc[stat.country] = (acc[stat.country] || 0) + 1;
        return acc;
    }, {});

    let cityCounts = linkStatistics.reduce((acc, stat) => {
        acc[stat.city] = (acc[stat.city] || 0) + 1;
        return acc;
    }, {});

    let operatingSystemCounts = linkStatistics.reduce((acc, stat) => {
        acc[stat.device] = (acc[stat.device] || 0) + 1;
        return acc;
    }, {});

    let browserCounts = linkStatistics.reduce((acc, stat) => {
        acc[stat.browser] = (acc[stat.browser] || 0) + 1;
        return acc;
    }, {});

    let ispCounts = linkStatistics.reduce((acc, stat) => {
        acc[stat.isp] = (acc[stat.isp] || 0) + 1;
        return acc;
    }, {});

    let source = linkStatistics.reduce((acc, stat) => {
        acc[stat.source] = (acc[stat.source] || 0) + 1;
        return acc;
    }, {});

    // Convert the counts to an array of [country, count] pairs
    // let countries = Object.entries(countryCounts);
    let operatingSystems = Object.entries(operatingSystemCounts);
    let countries = Object.entries(countryCounts);
    let cities = Object.entries(cityCounts);
    // let operatingSystems = Object.entries(operatingSystemCounts).map(([operatingSystem, count]) => `${operatingSystem}${count}`);
    let browsers = Object.entries(browserCounts);
    let isps = Object.entries(ispCounts);
    let sources = Object.entries(source);


    // Sort the array by count in descending order and take the top 10
    let top10Countries = countries.sort((a, b) => b[1] - a[1]).slice(0, 10);
    let top10Cities = cities.sort((a, b) => b[1] - a[1]).slice(0, 10);
    let top10OperatingSystems = operatingSystems.sort((a, b) => b[1] - a[1]).slice(0, 10);
    let top10Browsers = browsers.sort((a, b) => b[1] - a[1]).slice(0, 10);
    let top10Isps = isps.sort((a, b) => b[1] - a[1]).slice(0, 10);
    let top10Sources = sources.sort((a, b) => b[1] - a[1]).slice(0, 10);

    let top10CountriesWithCommas = top10Countries.map((country, index) => {
        return country + ' / ';
    });

    let top10CitiesWithCommas = top10Cities.map((city, index) => {
        return city + ' / ';
    });

    let top10OperatingSystemsWithCommas = top10OperatingSystems.map((operatingSystem, index) => {
        return operatingSystem + ' / ';
    });

    let top10BrowsersWithCommas = top10Browsers.map((browser, index) => {
        return browser + ' / ';
    });

    let top10IspsWithCommas = top10Isps.map((isp, index) => {
        return isp + ' / ';
    });

    let top10SourcesWithCommas = top10Sources.map((source, index) => {
        return source + ' / ';
    });



    function splitCountryCount(countryCount) {
        let [splitCountry, splitCount] = countryCount.split(',');
        return [splitCountry, splitCount];
    }

    return (
        <>
            <ToastContainer />
            {loading === false ? (

                <Container fluid className={`container-fluid`}>
                    <div className='d-flex justify-content-between logoutbtn'>

                        <div>

                            <button
                                className="btn btn-outline-secondary my-3"
                                onClick={() => window.print()}
                            >
                                <BsPrinterFill /> Print Report
                            </button>

                            <button
                                className="btn btn-outline-secondary m-3"
                                onClick={(event) => {
                                    event.preventDefault();
                                    window.location = '/lists.adm';
                                }}
                            >
                                Back to List
                            </button>
                        </div>

                        <div className='navbar'>
                            <Navbar.Brand href="/home.adm">
                                <img
                                    src={constants.logo}
                                    width="40"
                                    className="d-inline-block align-center"
                                    alt="Edu.NUG Logo"
                                />
                            </Navbar.Brand>
                            <span className='slug text-muted'>/</span>
                            <div className='d-flex'>
                                <span className="slug text-muted">Statistic Report <BsDot /></span>
                                <span className='text-muted shortlink'>edu.nugmyanmar.org/{statisticsid}</span>
                            </div>
                            {/* <div className='d-flex'>
                                <input
                                    type="text"
                                    placeholder="🔎 Search by Title, Original Link or Shortern Link"
                                    value={searchTerm}
                                    onChange={handleSearchTermChange}
                                    className='searchBar'
                                />
                                <FaInfoCircle className='infoIcon' />
                            </div> */}
                        </div>

                    </div>

                    {/* <Modal show={showModal} onHide={() => setShowModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Info</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <p>{modalMessage}</p>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal> */}

                    <Col className="ms-sm-auto">
                        <Container className="datacontainer p-0">
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {dates.map((date, index) => (
                                    <Button
                                        className='mx-1 my-1'
                                        variant={selectedDate === date ? 'secondary' : 'outline-secondary'}
                                        size='sm' key={index}
                                        onClick={() => fetchLinkStatisticsAgain(date)}>
                                        {date}
                                    </Button>
                                ))}
                            </div>
                        </Container>
                        {statDataLoading ? (
                            <Loading />
                        ) : (
                            <Container className="datacontainer p-0">

                                {linkStatistics.length === 0 ? (
                                    <div className='my-3'>
                                        <Alert variant="secondary">
                                            <Alert.Heading>Statistic report is not available</Alert.Heading>
                                            <div>
                                                <img src="https://cdn.egov.nugmyanmar.org/assets/state/ondevelopment-go.nug.svg" alt="Edu.NUG Logo" width={100} />
                                            </div>
                                            <p className='mb-0'>
                                                No data available at the moment
                                            </p>
                                            <hr className='my-1' />
                                            <p className='mb-0'>This might have been created prior to <b>February 20, 2024</b>. Necessary database fields were not added earlier, preventing the visibility of your link statistic report.</p>
                                        </Alert>
                                        {/* <p>No data available</p> */}
                                    </div>
                                ) : (
                                    <div className='my-3'>
                                        <Row className='my-3'>
                                            <Col>
                                                <Alert variant="success">
                                                    <Alert.Heading>Number of Clicks</Alert.Heading>
                                                    <p className='mb-0' style={{ fontSize: '5rem' }}>
                                                        {linkStatistics.length}
                                                    </p>
                                                    <hr className='my-1' />
                                                    <p className="mb-0">
                                                        It's may be differ from the unique clicks
                                                    </p>
                                                </Alert>
                                            </Col>
                                            <Col>
                                                <Alert variant="success">
                                                    <Alert.Heading>Number of Unique Clicks</Alert.Heading>
                                                    <p className='mb-0' style={{ fontSize: '5rem' }}>
                                                        {new Set(linkStatistics.map(stat => stat.ip)).size}
                                                    </p>
                                                    <hr className='my-1' />
                                                    <p className="mb-0">
                                                        It's may be differ from the unique clicks
                                                    </p>
                                                </Alert>
                                            </Col>
                                            <Col>
                                                <Alert variant="success">
                                                    <Alert.Heading>Number of Countries</Alert.Heading>
                                                    <p className='mb-0' style={{ fontSize: '5rem' }}>
                                                        {new Set(linkStatistics.map(stat => stat.country)).size}
                                                    </p>
                                                    <hr className='my-1' />
                                                    <p className="mb-0">
                                                        It's may be differ from the unique clicks
                                                    </p>
                                                </Alert>
                                            </Col>
                                            <Col>
                                                <Alert variant="success">
                                                    <Alert.Heading>Number of Cities</Alert.Heading>
                                                    <p className='mb-0' style={{ fontSize: '5rem' }}>
                                                        {new Set(linkStatistics.map(stat => stat.city)).size}
                                                    </p>
                                                    <hr className='my-1' />
                                                    <p className="mb-0">
                                                        It's may be differ from the unique clicks
                                                    </p>
                                                </Alert>
                                            </Col>
                                        </Row>

                                        <Masonry
                                            breakpointCols={breakpointColumnsObj}
                                            className="my-masonry-grid my-3"
                                            columnClassName="my-masonry-grid_column"
                                        >
                                            <Col>
                                                <Alert variant="secondary">
                                                    <Alert.Heading>Top 10 Countries</Alert.Heading>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>Country</th>
                                                                <th>Count</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='tabledata'>
                                                            {top10CountriesWithCommas.map((country, index) => (
                                                                <tr key={index}>
                                                                    <td>{country.split(',')[0].trim()}</td>
                                                                    <td>{country.split(',')[1].trim().split('/')[0]}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    <hr className='my-1' />
                                                    <p className="mb-0">
                                                        It's may be differ from the unique clicks
                                                    </p>
                                                </Alert>
                                            </Col>
                                            <Col>
                                                <Alert variant="secondary">
                                                    <Alert.Heading>Top 10 Cities</Alert.Heading>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>City</th>
                                                                <th>Count</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='tabledata'>
                                                            {top10CitiesWithCommas.map((city, index) => (
                                                                <tr key={index}>
                                                                    <td>{city.split(',')[0].trim()}</td>
                                                                    <td>{city.split(',')[1].trim().split('/')[0]}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    <hr className='my-1' />
                                                    <p className="mb-0">
                                                        It's may be differ from the unique clicks
                                                    </p>
                                                </Alert>
                                            </Col>
                                            <Col>
                                                <Alert variant="secondary">
                                                    <Alert.Heading>Top 10 Operating Systems</Alert.Heading>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>OS</th>
                                                                <th>Count</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='tabledata'>
                                                            {top10OperatingSystemsWithCommas.map((operatingSystem, index) => (
                                                                <tr key={index}>
                                                                    <td>{operatingSystem.split(',')[0].trim()}</td>
                                                                    <td>{operatingSystem.split(',')[1].trim().split('/')[0]}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    <hr className='my-1' />
                                                    <p className="mb-0">
                                                        It's may be differ from the unique clicks
                                                    </p>
                                                </Alert>
                                            </Col>
                                            <Col>
                                                <Alert variant="secondary">
                                                    <Alert.Heading>Top 10 Browsers</Alert.Heading>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>Browser</th>
                                                                <th>Count</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='tabledata'>
                                                            {top10BrowsersWithCommas.map((browser, index) => (
                                                                <tr key={index}>
                                                                    <td>{browser.split(',')[0].trim()}</td>
                                                                    <td>{browser.split(',')[1].trim().split('/')[0]}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    <hr className='my-1' />
                                                    <p className="mb-0">
                                                        It's may be differ from the unique clicks
                                                    </p>
                                                </Alert>
                                            </Col>
                                            <Col>
                                                <Alert variant="secondary">
                                                    <Alert.Heading>Top 10 ISPs</Alert.Heading>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>ISP</th>
                                                                <th>Count</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='tabledata'>
                                                            {top10IspsWithCommas.map((isp, index) => (
                                                                <tr key={index}>
                                                                    <td>{isp.split(',')[0].trim()}</td>
                                                                    <td>{isp.split(',')[1].trim().split('/')[0]}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    <hr className='my-1' />
                                                    <p className="mb-0">
                                                        It's may be differ from the unique clicks
                                                    </p>
                                                </Alert>
                                            </Col>
                                            <Col>
                                                <Alert variant="secondary">
                                                    <Alert.Heading>Top 10 Sources</Alert.Heading>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>Source</th>
                                                                <th>Count</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='tabledata'>
                                                            {top10SourcesWithCommas.map((source, index) => (
                                                                <tr key={index}>
                                                                    <td>{source.split(',')[0].trim()}</td>
                                                                    <td>{source.split(',')[1].trim().split('/')[0]}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    <hr className='my-1' />
                                                    <p className="mb-0">
                                                        It's may be differ from the unique clicks
                                                    </p>
                                                </Alert>
                                            </Col>
                                        </Masonry>

                                        {/* <Row className='my-3'>
                                            <Col md={4}>
                                                <Alert variant="secondary">
                                                    <Alert.Heading>Top 10 Countries</Alert.Heading>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>Country</th>
                                                                <th>Count</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='tabledata'>
                                                            {top10CountriesWithCommas.map((country, index) => (
                                                                <tr key={index}>
                                                                    <td>{country.split(',')[0].trim()}</td>
                                                                    <td>{country.split(',')[1].trim().split('/')[0]}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    <hr className='my-1' />
                                                    <p className="mb-0">
                                                        It's may be differ from the unique clicks
                                                    </p>
                                                </Alert>
                                            </Col>
                                            <Col md={4}>
                                                <Alert variant="secondary">
                                                    <Alert.Heading>Top 10 Cities</Alert.Heading>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>City</th>
                                                                <th>Count</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='tabledata'>
                                                            {top10CitiesWithCommas.map((city, index) => (
                                                                <tr key={index}>
                                                                    <td>{city.split(',')[0].trim()}</td>
                                                                    <td>{city.split(',')[1].trim().split('/')[0]}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    <hr className='my-1' />
                                                    <p className="mb-0">
                                                        It's may be differ from the unique clicks
                                                    </p>
                                                </Alert>
                                            </Col>
                                            <Col md={4}>
                                                <Alert variant="secondary">
                                                    <Alert.Heading>Top 10 Operating Systems</Alert.Heading>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>OS</th>
                                                                <th>Count</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='tabledata'>
                                                            {top10OperatingSystemsWithCommas.map((operatingSystem, index) => (
                                                                <tr key={index}>
                                                                    <td>{operatingSystem.split(',')[0].trim()}</td>
                                                                    <td>{operatingSystem.split(',')[1].trim().split('/')[0]}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    <hr className='my-1' />
                                                    <p className="mb-0">
                                                        It's may be differ from the unique clicks
                                                    </p>
                                                </Alert>
                                            </Col>
                                            <Col md={4}>
                                                <Alert variant="secondary">
                                                    <Alert.Heading>Top 10 Browsers</Alert.Heading>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>Browser</th>
                                                                <th>Count</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='tabledata'>
                                                            {top10BrowsersWithCommas.map((browser, index) => (
                                                                <tr key={index}>
                                                                    <td>{browser.split(',')[0].trim()}</td>
                                                                    <td>{browser.split(',')[1].trim().split('/')[0]}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    <hr className='my-1' />
                                                    <p className="mb-0">
                                                        It's may be differ from the unique clicks
                                                    </p>
                                                </Alert>
                                            </Col>
                                            <Col md={4}>
                                                <Alert variant="secondary">
                                                    <Alert.Heading>Top 10 ISPs</Alert.Heading>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>ISP</th>
                                                                <th>Count</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='tabledata'>
                                                            {top10IspsWithCommas.map((isp, index) => (
                                                                <tr key={index}>
                                                                    <td>{isp.split(',')[0].trim()}</td>
                                                                    <td>{isp.split(',')[1].trim().split('/')[0]}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    <hr className='my-1' />
                                                    <p className="mb-0">
                                                        It's may be differ from the unique clicks
                                                    </p>
                                                </Alert>
                                            </Col>
                                            <Col md={4}>
                                                <Alert variant="secondary">
                                                    <Alert.Heading>Top 10 Sources</Alert.Heading>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>Source</th>
                                                                <th>Count</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='tabledata'>
                                                            {top10SourcesWithCommas.map((source, index) => (
                                                                <tr key={index}>
                                                                    <td>{source.split(',')[0].trim()}</td>
                                                                    <td>{source.split(',')[1].trim().split('/')[0]}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    <hr className='my-1' />
                                                    <p className="mb-0">
                                                        It's may be differ from the unique clicks
                                                    </p>
                                                </Alert>
                                            </Col>
                                        </Row> */}

                                        {/* <p>Top 10 Countries <br />{top10CountriesWithCommas}</p>
                                        <p>Top 10 Cities <br />{top10CitiesWithCommas}</p>
                                        <p>Top 10 Operating Systems <br />{top10OperatingSystemsWithCommas}</p>
                                        <p>Top 10 Browsers <br />{top10BrowsersWithCommas}</p>
                                        <p>Top 10 ISPs <br />{top10IspsWithCommas}</p>
                                        <p>Top 10 Sources <br />{top10SourcesWithCommas}</p> */}

                                    </div>
                                )}


                                <div className="footerMax">
                                    <p className="text-muted">© {currentYear} NUG Myanmar </p>
                                </div>

                            </Container>
                        )}

                    </Col >

                </Container >
            ) : (
                <>
                    <Loading />
                </>
            )
            }
        </>
    );

    // return <Bar options={options} data={data} />;
}