import React, { useEffect, useState } from 'react';
import '../../App.css';
import { getUserListData } from '../../firebase.js';
import { sendPasswordResetEmail, updateProfile } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navbar, Container, Modal, Col, Table, Button, Pagination } from "react-bootstrap";
import { BiTrash, BiCopy, BiExpand } from "react-icons/bi";
import { MdLockReset, MdNoAccounts } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import Countdown from 'react-countdown';
import { auth, SECRET_KEY } from "../../firebase.js";
import Loading from "../Loading/Loading";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { toast, ToastContainer } from 'react-toastify';
import '../List/list.css';
import { constants } from "../../Global/constants.js";

function LabelWithMaxLength({ text, maxLength }) {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    const openTextOnModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const copyToClipboard = () => {
        // Create a temporary textarea element to copy text to the clipboard
        const textArea = document.createElement("textarea");
        textArea.value = text;

        // Append the textarea to the document
        document.body.appendChild(textArea);

        // Select the text in the textarea
        textArea.select();

        // Copy the selected text to the clipboard
        document.execCommand("copy");

        // Remove the textarea from the document
        document.body.removeChild(textArea);

        // Set the "isCopied" state to true to show a copy confirmation
        setIsCopied(true);

        // Reset the "isCopied" state after a few seconds
        setTimeout(() => setIsCopied(false), 2000);

        toast.success('Copied data to clipboard!', {});
    };

    return (
        <div>
            <label>{text.length > maxLength ? text.substring(0, maxLength) + "..." : text}</label>
            <button onClick={copyToClipboard} className="copy-button">
                {isCopied ? <IoMdCheckmarkCircleOutline /> : <BiCopy />}
            </button>

            <Modal show={modalIsOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Expand View <FaEye /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ overflowWrap: 'break-word' }}>{text}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={copyToClipboard} >
                        {isCopied ? 'Copied' : 'Copy'}
                    </Button>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <button onClick={openTextOnModal} className="copy-button">
                {modalIsOpen ? <IoMdCheckmarkCircleOutline /> : (text.length < maxLength ? "" : <FaEye />)}
            </button>
            {/* {isCopied && <span>Copied!</span>} */}
        </div>
    );
}

function UserLists() {

    const currentYear = new Date().getFullYear();
    const [shortenLists, addData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [user, cusloading, error] = useAuthState(auth);

    const [currentUserIsAdmin, setCurrentUserIsAdmin] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const currentItems = shortenLists.slice(firstIndex, lastIndex);

    const totalPages = Math.ceil(shortenLists.length / itemsPerPage);

    useEffect(() => {
        setCurrentUserIsAdmin(localStorage.getItem('iam'));
    }, []);

    const passwordReset = (data) => {
        sendPasswordResetEmail(auth, data.email)
            .then(() => {
                setModalMessage('Password reset email sent successfully!');
                setShowModal(true);
            })
            .catch((error) => {
                setModalMessage('Error sending password reset email:', error.message);
                setShowModal(true);
            });
    };

    useEffect(() => {
        if (!cusloading && !user) {
            window.location.href = '/';
        }
    }, [user, cusloading]);

    useEffect(() => {
        getUserListData(currentUserIsAdmin)
            .then((lists) => {
                console.log('Fetched data:', lists); // Add this line
                addData(lists);
                setLoading(false);
            })
            .catch((err) => console.log(err));
    }, []);

    // function deletelists(link) {
    //     const shortenurlData = link;
    //     addData(shortenLists.filter((item) => item.id !== shortenurlData.id));
    //     setLoading(true);
    //     removeShortenLinksData(shortenurlData.id).then((res) => {
    //         setTimeout(() => setLoading(false), 1000);
    //     });
    // }

    const handleCountdownComplete = () => {
        auth.signOut()
            .then(() => {
                // Logout successful
                console.log('User logged out.');
                localStorage.removeItem('ue');
                localStorage.clear();
                window.localStorage.clear();
                window.location = '/';
            })
            .catch((error) => {
                // Handle logout error
                console.error('Error logging out:', error);
            });
    };

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <handleCountdownComplete />;
        } else {
            // Render a countdown
            return <span className="expireOnAlign">Expired On : {minutes}:{seconds}</span>;
        }
    };

    return (
        <>
            {loading === false ? (
                <>
                    <ToastContainer />
                    <Container fluid>
                        <div className='d-flex justify-content-between logoutbtn'>

                            <div>
                                <Countdown
                                    className="m-3"
                                    date={Date.now() + 300000}
                                    renderer={renderer}
                                    onComplete={handleCountdownComplete}
                                />
                                <button
                                    className="btn btn-outline-secondary m-3"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        window.location = '/home.adm';
                                    }}
                                >
                                    Back to Home
                                </button>
                            </div>

                            <div className='navbar'>
                                <Navbar.Brand href="/home.adm">
                                    <img
                                        src={constants.logo}
                                        width="40"
                                        className="d-inline-block align-center"
                                        alt="Edu.NUG Logo"
                                    />
                                </Navbar.Brand>
                                <span className='slug text-muted'>/</span>
                                <span className="slug text-muted">User Lists</span>
                            </div>

                        </div>

                        <Col className="ms-sm-auto">
                            <Container className="datacontainer p-0">
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th md={2}>Name</th>
                                            <th md={4}>Email</th>
                                            <th md={4}>Gov Agency</th>
                                            <th md={2}>Approved by</th>
                                            <th><MdLockReset /></th>
                                        </tr>
                                    </thead>
                                    <tbody className="tabledata">
                                        {shortenLists.length === 0 ? (
                                            <tr>
                                                <td colSpan="5" className="text-center">
                                                    No data available
                                                </td>
                                            </tr>
                                        ) : (
                                            shortenLists.map((data, index) => (
                                                <tr key={index} className="py-4">
                                                    <td>
                                                        <label>{index + 1}.</label>
                                                    </td>
                                                    <td md={2}>
                                                        <LabelWithMaxLength text={data.name} maxLength={60} />
                                                    </td>
                                                    <td md={4}>
                                                        <LabelWithMaxLength text={data.email} maxLength={40} />
                                                    </td>
                                                    <td md={4}>
                                                        <LabelWithMaxLength text={data.govagency} maxLength={40} />
                                                    </td>
                                                    <td md={2}>
                                                        <LabelWithMaxLength text={data.approvedby} maxLength={40} />
                                                    </td>
                                                    <td>
                                                        <Button variant="secondary" size="sm" onClick={() => passwordReset(data)}>
                                                            <MdLockReset />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )))}

                                    </tbody>
                                </Table>
                                <Pagination>
                                    <Pagination.First onClick={() => setCurrentPage(1)} />
                                    <Pagination.Prev onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)} />
                                    {[...Array(totalPages).keys()].map(page =>
                                        <Pagination.Item key={page + 1} active={page + 1 === currentPage} onClick={() => setCurrentPage(page + 1)}>
                                            {page + 1}
                                        </Pagination.Item>
                                    )}
                                    <Pagination.Next onClick={() => setCurrentPage(currentPage < totalPages ? currentPage + 1 : totalPages)} />
                                    <Pagination.Last onClick={() => setCurrentPage(totalPages)} />
                                </Pagination>
                            </Container>
                        </Col>
                    </Container>

                    <Modal show={showModal} onHide={() => setShowModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Info</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <p>{modalMessage}</p>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            ) : (
                <Loading />
            )}
        </>
    );
}

export default UserLists;