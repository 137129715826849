import React, { Component, useState } from 'react';
import LoadingSceen from '../Loading/Loading';
import './FormSuccess.css';
import { Button, Modal, Navbar } from 'react-bootstrap';
import { constants } from "../../Global/constants.js";

export default class LocalComplaint extends Component {

    state = {
        "showModal": false,
        "modalMessage": "",
        "loading": true,
        "code": localStorage.getItem("code"),
        "message": localStorage.getItem("message"),
        "generatedURL": "https://edu.nugmyanmar.org/" + localStorage.getItem("generatedURL")
    }
    componentDidMount() {
        setTimeout(() => this.setState({ loading: false }), 2000);
    }

    showModal = (show) => {
        this.setState({ showModal: show });
    }

    modalMessage = (message) => {
        this.setState({ modalMessage: message });
    }
    render() {
        console.log(this.state.code);
        // if (localStorage.getItem("code") == 200) {
        //     emailjs.send("mocitmail_n5xxnfy", "template_iviynab", {
        //         linkTitle: linkTitle,
        //         RECIPIENT_EMAIL: auth.currentUser.email,
        //         originalLink: originalLink,
        //         idcode: idcode,
        //     }).then(function (response) {
        //         console.log('SUCCESS!', response.status, response.text);
        //     }, function (error) {
        //         console.log('FAILED...', error);
        //     });
        // }
        const goBackHome = () => {
            window.location = '/';
            localStorage.removeItem("code");
            localStorage.removeItem("message");
            localStorage.removeItem("generatedURL");
        };
        return (
            <>
                <Modal show={this.state.showModal} onHide={() => this.showModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Info</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>{this.state.modalMessage}</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.showModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                {this.state.loading === false ? (
                    <div className="App" >
                        <header className="bg animate mheight">
                            {/* <div className='d-flex'>
                                <button className='btn btn-outline-secondary m-3' onClick={goBackHome}>Back To Home</button>
                            </div> */}
                            <div className='d-flex justify-content-between logoutbtn'>

                                <div>
                                    {/* <Countdown
                                        className="m-3"
                                        date={Date.now() + 300000}
                                        renderer={renderer}
                                        onComplete={handleCountdownComplete}
                                    /> */}
                                    <button
                                        className="btn btn-outline-secondary m-3"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            window.location = '/home.adm';
                                        }}
                                    >
                                        Back to Home
                                    </button>
                                </div>

                                <div className='navbar'>
                                    <Navbar.Brand href="/home.adm">
                                        <img
                                            src={constants.logo}
                                            width="40"
                                            className="d-inline-block align-center"
                                            alt="Edu.NUG Logo"
                                        />
                                    </Navbar.Brand>
                                    <span className='slug text-muted'>/</span>
                                    <span className="slug text-muted">Transmission Status</span>
                                </div>
                            </div>

                            {/* </div> */}
                            {this.state.code === "200" ? (
                                <div className="text-Box">
                                    <img src='https://nationalunitygovernment.b-cdn.net/assets/state/successmark.png' alt='Successful Mark' width={200} />
                                    <p className='h6 lh-lg'>
                                        Shorten URL Generated Successfully
                                    </p>
                                    <div className='h4 lh-lg'>
                                        {this.state.generatedURL === null ? (
                                            "Getting URL from server is failed! Please try again later."
                                        ) : (this.state.generatedURL)}
                                    </div>
                                    <Button variant="primary" className='copylinkbtn' onClick={() => {
                                        navigator.clipboard.writeText(this.state.generatedURL)
                                            .then(() => {
                                                // console.log('Text copied to clipboard:', this.state.generatedURL);
                                                // alert('Custom link copied to clipboard:', this.state.generatedURL);
                                                this.modalMessage('Custom link copied to clipboard as follow: ' + this.state.generatedURL);
                                                this.showModal(true);
                                                // You can show a success message or perform any other desired actions
                                            })
                                            .catch((error) => {
                                                // console.error('Error copying text to clipboard:', error);
                                                // alert('Error copying custom link to clipboard:', this.state.generatedURL);
                                                this.modalMessage('Error copying custom link to clipboard caused by: ' + error);
                                                this.showModal(true);
                                                // You can show an error message or handle the error in any way you want
                                            });
                                    }}>Copy to Clipboard</Button>
                                </div>
                            ) : (
                                <div className="text-danger text-Box">
                                    <img src='https://nationalunitygovernment.b-cdn.net/assets/state/unsuccessmark.png' alt='Unsuccessful Mark' width={200} />
                                    <p className='h4 lh-lg'>
                                        The data transmission process encountered a failure.
                                    </p>
                                    {this.state.message === null ? (
                                        "No form filled out or server is down! Please try again later."
                                    ) : (this.state.message)}
                                </div>
                            )}
                        </header>
                    </div>
                ) : (
                    <LoadingSceen />
                )}
            </>
        );
    }
}